<template>
    <layout class="employees" :loading="loading">
        <div class="products">
            <h2 class="mb-4">Products / {{ products ? products[0]?.category?.title : '' }}</h2>
            <div class="row" v-if="products">
                <div
                        v-for="product in products"
                        :key="product.id+currentId"
                        :class="cardSize(product)"
                        class="d-flex product"
                >
                    <b-card class="w-100 mb-0">
                        <div class="product_img mr-3" v-if="product.icon">
                            <img :src="product.icon"/>
                        </div>
                        <div class="product_info">
                            <div class="product_title font-size-16 font-weight-bold">{{ product.title }}</div>
                            <div class="product_platforms font-size-14">
                                <div
                                        v-for="platform in product.platforms"
                                        :key="platform.id"
                                        class="mt-1"
                                >
                                    <a :href="platform.pivot.link" target="_blank">{{ platform.title }}</a>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>
        </div>
    </layout>
</template>
<script>
    export default {
        name: 'Products',
        data() {
            return {
                loading: false,
                currentId: null
            }
        },
        async mounted() {
            this.loading = true;
            await this.$store.dispatch('products/getProducts', this.$route.params.id);
            this.currentId = this.$route.params.id
            this.loading = false;
        },
        methods:{
            cardSize(product){
                if(product.category.ratio == 2){ //16:9
                    return 'col-md-6 col-lg-12 col-xl-6 ratio-169'
                }else{ //1:1
                    return 'col-sm-6 col-lg-6 col-xl-4 ratio-11'
                }
            }
        },
        watch:{
            '$route.params.id': async function(id) {
              this.loading = true;
              await this.$store.dispatch('products/getProducts', id);
              this.currentId = this.$route.params.id
              this.loading = false;
            }
        },
        computed: {
            products() {
                return this.$store.state.products.products;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .products{
        .product{
            margin-bottom: 24px;
        }
        .card-body{
            display: flex;
        }
        .product_img{
            img{
                border-radius: .25rem;
            }
        }
    }
    .ratio-169{
        .product_img{
            img{
                max-width: 200px;
            }
        }
    }
    .ratio-11{
        .product_img{
            img{
                max-width: 120px;
            }
        }
    }
    @media (min-width: 1600px){
        .col-xl-4{
            max-width: 25%;
        }
        .col-xl-6{
            max-width: 33.3%;
        }
    }
</style>
